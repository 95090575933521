
import { HeaderFieldType } from '@/shared/types';

export const documentsListHeaders: HeaderFieldType[] = [
    { text: 'Tytuł', value: 'name' },
    { text: 'Twórca', value: 'creator', align: 'left' },
    {text: 'Kategoria', value: 'category', align: 'left'},
    {text: 'Tagi', value: 'tags', align: 'left'},
    { text: 'Utworzony', value: 'created_at', align: 'left' },
    { text: 'Zmodyfikowany', value: 'updated_at', align: 'left' },
    {text: 'Status', value: 'status', align: 'left'},

    // TODO: uncomment when documents archive is done
   // { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];
export const documentTemplatesListHeaders: HeaderFieldType[] = [
    { text: 'Tytuł', value: 'name' },
    { text: 'Twórca', value: 'creator', align: 'left' },
    {text: 'Kategoria', value: 'category', align: 'left'},
    {text: 'Tagi', value: 'tags', align: 'left'},
    { text: 'Utworzony', value: 'created_at', align: 'left' },
    { text: 'Zmodyfikowany', value: 'updated_at', align: 'left' },
    {text: 'Status', value: 'status', align: 'left'},
    // { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];

export const documentsCategoriesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    {
        text: 'Akcje',
        align: 'center',
        sortable: false,
        value: 'actions',
    },
];
export const documentTagsListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    {
        text: 'Akcje',
        align: 'center',
        sortable: false,
        value: 'actions',
    },
];

