
import { documentStatuses } from '@/shared/config/documents';
import { parseDateTime } from '@/shared/helpers';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Document } from '@/modules/documents/shared/models/document';
import TextContrast from '@/shared/components/elements/text-contrast.vue';

@Component({
    components: {
        TextContrast,
    },
})
export default class DocumentDetailsSidePanel extends Vue {
    @Prop(Object) public item!: Document;
    public parseDateTime = parseDateTime;

    get publicStatus() {
        if (!this.item) {
            return null;
        }
        return documentStatuses.find((status: any) => status.id === this.item.public_status);
    }
    get privilegedStatus() {
        if (!this.item) {
            return null;
        }
        return documentStatuses.find((status: any) => status.id === this.item.privileged_status);
    }
}
