
import { Component, Watch } from 'vue-property-decorator';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import DocumentEditor from '@/modules/documents/components/editors/document-editor.vue';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import SidePanel from './side-panel.vue';
import { documentsModule, Document } from '../../shared';
import DocumentsForm from '../form/form.vue';
import DetailsToolbar from './details-toolbar.vue';
import FullEditAskDialog from '@/modules/documents/components/dialogs/full-edit-mode-dialog.vue';

// @ts-ignore
import pdf from 'vue-pdf-app';
import 'vue-pdf-app/dist/icons/main.css';
import { getUser } from '@/modules/admin/shared/services';

@Component({
    components: { DocumentEditor, TopToolbar, DocumentsForm, SidePanel, DetailsToolbar, pdf, FullEditAskDialog },
})
export default class DocumentsDetails extends DetailsComponent<Document> {
    public pdfLoaded = false;
    public editModeDialog = false;

    public userCurrentlyEditing = null;

    public store: any = this.$store.state.documentsState;
    public actionsTypes = documentsModule.actionsTypes;
    public documentEditMutation = documentsModule.mutationsTypes.START_EDIT_DOCUMENT;
    public showAction: string = this.actionsTypes.SHOW_ITEM;
    public showSidePanel = false;
    public restricted = true;

    public pdfViewerConfig = {
        toolbar: false,
    };

    get isMobile() {
        switch (this.$vuetify.breakpoint.name) {
            case 'xs':
                return true;
            case 'sm':
                return true;
            case 'md':
                return false;
            case 'lg':
                return false;
            case 'xl':
                return false;
        }
    }

    get isDarkTheme() {
        return this.$vuetify.theme.dark;
    }

    get editedItem() {
        return this.store.editedItem;
    }
    set editedItem(val: any) {
        this.store.editedItem = val;
    }

    get formDialog() {
        return this.store.formDialog;
    }
    set formDialog(val: any) {
        this.store.formDialog = val;
    }

    get editedDocuments() {
        return this.store.editedDocuments;
    }

    get isCurrentlyEdited() {
        if (!this.editedDocuments || !this.id) {
            return false;
        }
        const ids: any[] = this.editedDocuments.map((el: any) => el.id);
        return ids.includes(this.id);
    }

    get pdfComponentStyling() {
        return `
            height: calc(100vh - ${this.isCurrentlyEdited && this.userCurrentlyEditing ? '152px' : '112px'});
            transition: all 200ms ease
        `;
    }

    public created() {
        this.fetchDetails();
    }

    public mounted() {
        this.pdfLoaded = false;
    }

    public setupForm({ restricted }: any) {
        this.editedItem = this._.cloneDeep(this.item);
        if (!restricted) {
            this.editModeDialog = true;
            return;
        }
        this.restricted = restricted;
        this.formDialog = true;
        this.pdfLoaded = false;
    }

    public openFormWithFullEditMode(fullEditMode: boolean) {
        this.$store.commit(this.documentEditMutation, this.id);

        this.editModeDialog = false;
        this.restricted = !fullEditMode;
        this.formDialog = true;
        this.pdfLoaded = false;
    }

    public onPDFCreated(PDFViewerApp: any) {
        this.pdfLoaded = true;
        if (!PDFViewerApp.pdfSidebar.isOpen) {
            PDFViewerApp.pdfSidebar.toggle();
        }
        PDFViewerApp.pdfViewer.currentScaleValue = 'page-fit';
    }

    public itemSaved() {
        this.formDialog = false;
        this.pdfLoaded = false;
        this.fetchDetails();
    }

    @Watch('isCurrentlyEdited', { immediate: true })
    public async onLiveEdition(val: boolean) {
        this.userCurrentlyEditing = null;
        if (!val) {
            return;
        }
        const userID = this.editedDocuments.find((el: any) => Number(el.id) === Number(this.id)).userId;
        this.userCurrentlyEditing = (await getUser(userID)).data;
    }
}
