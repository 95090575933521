
import { Component, Inject, Prop, Watch } from 'vue-property-decorator';
import DocumentEditor from '@/modules/documents/components/editors/document-editor.vue';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import SidePanel from './side-panel.vue';
import { documentsModule, Document } from '../../shared';
import DocumentsForm from '../form/form.vue';
import { documentStatuses } from '@/shared/config/documents';
import Vue from 'vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
    components: { DocumentEditor, TopToolbar, DocumentsForm, SidePanel, TooltipBtn },
})
export default class DocumentsDetailsToolbar extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;

    @Prop(Object) public item!: any;
    @Prop(Boolean) public loading!: boolean;
    @Prop(Boolean) public forTemplate!: boolean;
    @Prop(Boolean) public isCurrentlyEdited!: boolean;
    public store: any = this.$store.state.documentsState;
    public actionsTypes = documentsModule.actionsTypes;
    public showAction: string = this.actionsTypes.SHOW_ITEM;
    public showSidePanel = false;
    public activeStatus: any = null;
    public isUserPrivileged: any = false;

    get formDialog() {
        return this.store.formDialog;
    }
    set formDialog(val: any) {
        this.store.formDialog = val;
    }

    get publicStatus() {
        if (!this.item) {
            return null;
        }
        return documentStatuses.find((status: any) => status.id === this.item.public_status);
    }
    get privilegedStatus() {
        if (!this.item) {
            return null;
        }
        return documentStatuses.find((status: any) => status.id === this.item.privileged_status);
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`${this.forTemplate ? 'documents.templates' : 'documents'}.${action}`);
    }

    public setEditMode() {
        this.isUserPrivileged = this.$store.state.authState.user?.is_admin || false;
        const activeStatusId = this.isUserPrivileged ? this.item.privileged_status : this.item.public_status;
        this.activeStatus = documentStatuses.find((status) => status.id === activeStatusId);
    }

    public download() {
        this.$store.dispatch(this.actionsTypes.DOWNLOAD, this.$route.params.id).then((response) => {
            if (response && response instanceof ArrayBuffer) {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.item.name + '.pdf');
                document.body.appendChild(link);
                link.click();
            }
        });
    }

    @Watch('loading')
    public onLoadingChange(val: any) {
        if (!val) {
            this.setEditMode();
        }
    }
}
