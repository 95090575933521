
import { Component, Watch } from 'vue-property-decorator';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import DocumentEditor from '@/modules/documents/components/editors/document-editor.vue';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import PreventUnload from '@/shared/PreventUnload.vue';
import { documentStatuses } from '@/shared/config/documents';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';
import Vue from 'vue';
import MetaDataForm from './form-meta-data.vue';

@Component({
    components: {
        FormActions,
        ActionConfirmDialog,
        DocumentEditor,
        TopToolbar,
        PreventUnload,
        AutoCompleteWithAddNew,
        InvestmentTagChip,
        MetaDataForm,
    },
    props: {
        itemData: Object,
        edit: {
            type: Boolean,
            default: false,
        },
        show: Boolean,
        next: {
            type: Boolean,
            default: true,
        },
        restricted: {
            type: Boolean,
            default: false,
        },
        store: Object,
    },
})
export default class DocumentsFormToolbar extends Vue {
    get formDialog() {
        return this.store.formDialog;
    }

    set formDialog(val: boolean) {
        this.store.formDialog = val;
    }
    get isSavingDisabled() {
        if (
            !this.itemData.content ||
            this.itemData.content.length <= 0 ||
            !this.itemData.name ||
            this.itemData.name.length < 3
        ) {
            return true;
        } else {
            return false;
        }
    }

    get isTitleFieldDisabled() {
        return this.$props.restricted && !!this.itemData.id;
    }
    public itemData!: any;
    public store!: any;

    public metaDataDialog = false;
    public documentStatuses = documentStatuses;
    public documentPrivilegedStatuses = documentStatuses.filter((item) => item.id !== 'secret');
    public prvillegedStatuses = this.getPrvillegedStatuses();

    public isMetaDataDialogValid = false;

    public publicStatusColor = this.getColorOfStatus('public_status');

    public privilegedStatusColor = this.getColorOfStatus('privileged_status');

    public mounted() {
        if (!this.$props.edit) {
            if (
                (!this.itemData.public_status || this.itemData.public_status.length === 0) &&
                (!this.itemData.privileged_status || this.itemData.privileged_status.length === 0)
            ) {
                this.$set(this.itemData, 'public_status', 'restricted');
                this.$set(this.itemData, 'privileged_status', 'fully-editable');
            } else {
                this.$set(this.itemData, 'public_status', this.itemData.public_status);
                this.$set(this.itemData, 'privileged_status', this.itemData.privileged_status);
            }
        }
    }

    public publicStatusInput(event: any) {
        Vue.set(this.itemData, 'public_status', event);
        this.publicStatusColor = this.getColorOfStatus('public_status');
        this.prvillegedStatuses = this.getPrvillegedStatuses();

        if (this.prvillegedStatuses.map((item) => item.id).includes(this.itemData.privileged_status)) {
            return;
        } else {
            this.$set(
                this.itemData,
                'privileged_status',
                this.prvillegedStatuses[this.prvillegedStatuses.length - 1].id,
            );
            this.privilegedStatusColor = this.getColorOfStatus('privileged_status');
        }
    }
    public privilegedStatusInput(event: any) {
        Vue.set(this.itemData, 'privileged_status', event);
        this.privilegedStatusColor = this.getColorOfStatus('privileged_status');
    }

    public getColorOfStatus(statusType: string) {
        return documentStatuses.find((status: any) => status.id === this.itemData[statusType])?.color || '#FFF';
    }

    public getPrvillegedStatuses() {
        const index = documentStatuses.findIndex((status: any) => status.id === this.itemData.public_status);
        const statuses = documentStatuses.slice(0, index >= 0 ? index : 0);
        if (statuses.length === 0) {
            statuses.push(documentStatuses[0]);
        }
        return statuses;
    }

    public getPrependIconElement(refName: string) {
        this.$nextTick(() => {
            const refElement = this.$refs[refName] as any;
            if (!refElement) {
                return;
            }
            return refElement.$refs['prepend-inner'].firstChild;
        });
    }
}
