import { render, staticRenderFns } from "./template-item.vue?vue&type=template&id=f5a57e30&scoped=true&"
import script from "./template-item.vue?vue&type=script&lang=ts&"
export * from "./template-item.vue?vue&type=script&lang=ts&"
import style0 from "./template-item.vue?vue&type=style&index=0&id=f5a57e30&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5a57e30",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VAvatar,VCard,VChip})
