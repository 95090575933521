
/**
 *  Dependencies list:
 *  - shared
 *  - module
 */
import { Component, Inject, Watch } from 'vue-property-decorator';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import DocumentEditor from '@/modules/documents/components/editors/document-editor.vue';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import PreventUnload from '@/shared/PreventUnload.vue';
import { documentStatuses } from '@/shared/config/documents';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import { documentCategoriesModule, documentTagsModule } from '../../shared/state/submodules';
import categoriesForm from '@/modules/documents/components/settings/categories/form.vue';
import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';
import TagForm from '@/modules/documents/components/settings/tags/form.vue';
import Vue from 'vue';
import { FormRules } from '@/shared/validation/form-rules';

@Component({
    components: {
        FormActions,
        ActionConfirmDialog,
        DocumentEditor,
        TopToolbar,
        PreventUnload,
        AutoCompleteWithAddNew,
        InvestmentTagChip,
    },
    props: {
        itemData: Object,
        restricted: {
            type: Boolean,
            default: false,
        },
        value: Boolean,
        beforeEditor: {
            type: Boolean,
            default: false,
        },
    },
})
export default class DocumentsMetaDataForm extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;

    get errorMessages() {
        return this.store ? this.store.errorMessages : {};
    }

    get tagsLoading() {
        return this.tagsState.loading;
    }

    get tagLoading() {
        return this.tagsState.loadingItem;
    }

    get tags() {
        return this.tagsState.data;
    }

    get tagEdited() {
        return this.tagsState.editedItem;
    }

    set tagEdited(value) {
        this.$store.commit(this.tagsModule.mutationsTypes.UPDATE_EDITED, value);
    }

    get isSavingDisabled() {
        if (
            !this.itemData.content ||
            this.itemData.content.length <= 0 ||
            !this.itemData.name ||
            this.itemData.name.length < 3
        ) {
            return true;
        } else {
            return false;
        }
    }
    public itemData!: any;
    public store = this.$store.state.documentsState;
    public metaDataDialog = false;
    public formRules = new FormRules();
    public documentDataDialog = false;

    public categoriesState = this.store.categoriesState;
    public tagsState = this.store.tagsState;
    public tagsModule = documentTagsModule;
    public categoriesModule = documentCategoriesModule;
    public categoryForm = categoriesForm;
    public TagForm = TagForm;
    public newTagDialog: boolean = false;
    public tagsSearch: any = null;
    public searchTag: string = '';

    public isValid = false;

    public mounted() {
        this.clearError('document_category_id');
        if (!this.$props.beforeEditor) {
            (document.querySelector('.v-overlay') as any).addEventListener(
                'click',
                (event: any) => {
                    this.$nextTick(() => {
                        this.$emit('close', this.validate());
                    });
                },
                false,
            );
        }
    }
    public clearError(name: string) {
        if (this.errorMessages[name]) {
            delete this.errorMessages[name];
            if (this.errorMessages.length > 0) {
                return;
            }
            return (this.store.error = '');
        }
    }

    public updateTags(tags: any[]) {
        this.isValid = false;
        if (!this.itemData.tags) {
            return;
        }
        const tag = this.itemData.tags[this.itemData.tags.length - 1];
        if (typeof tag === 'string') {
            this.newTagDialog = true;
            this.tagEdited = { name: tag };
        } else {
            this.searchTag = '';
        }
    }

    public updated() {
        this.validate();
    }

    public searchTags(search: string) {
        clearTimeout(this.tagsSearch);

        this.tagsSearch = setTimeout(() => {
            if (search && search.length >= 3) {
                this.$store.dispatch(this.tagsModule.actionsTypes.FETCH_DATA, { filters: { search } });
            }
        }, 500);
    }

    public removeNewTag() {
        this.itemData.tags.splice(this.itemData.tags.length - 1, 1);
        this.newTagDialog = false;
        this.isValid = true;
    }

    public createTag() {
        this.$store
            .dispatch(this.tagsModule.actionsTypes.STORE_ITEM, this.tagEdited)
            .then((response: { data: never }) => {
                if (response.data) {
                    this.itemData.tags.splice(this.itemData.tags.length - 1, 1);
                    this.itemData.tags.push(response.data);
                    this.newTagDialog = false;
                    this.isValid = true;
                }
            });
    }

    public isString(value: any) {
        return typeof value === 'string';
    }

    public validate() {
        this.$nextTick(() => {
            if (this.searchTag && this.searchTag.length > 0) {
                this.isValid = false;

                return;
            }
            if (this.itemData.tags && this.itemData.tags.length > 0) {
                if (typeof this.itemData.tags[this.itemData.tags.length - 1] === 'string') {
                    this.isValid = false;
                    return;
                }
            }
            if (!this.itemData.category_id) {
                this.isValid = false;
                return;
            }

            this.isValid = true;
        });
        return this.isValid;
    }

    public include() {
        if ((this.$refs.newTagDialog as any) && (this.$refs.newTagDialog as any).el) {
            return [(this.$refs.newTagDialog as any).el];
        } else {
            return [];
        }
    }
}
