import { render, staticRenderFns } from "./tile.vue?vue&type=template&id=2965aa0a&scoped=true&"
import script from "./tile.vue?vue&type=script&lang=ts&"
export * from "./tile.vue?vue&type=script&lang=ts&"
import style0 from "./tile.vue?vue&type=style&index=0&id=2965aa0a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2965aa0a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
installComponents(component, {VAvatar,VCard,VChip,VIcon,VImg,VOverlay,VProgressCircular,VResponsive})
