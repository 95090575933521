export const documentStatuses = [
    {
        id: 'fully-editable', name: 'Pełna Edycja', color: '#22a331',
        description: 'Pozwala na pełną edycje dokumentu', descriptionBeforeUse: 'Wypełnij luki i edytuj szablon'
    },
    {
        id: 'restricted', name: 'Ograniczona edycja', color: '#c48525',
        description: 'Pozwala na wypełnianie luk określonych w szablonie', descriptionBeforeUse: 'Wypełnij tylko luki'
    },
    {
        id: 'read-only', name: 'Zakaz Edycji', color: '#c71010',
        description: 'Zakazuje jakiejkolwiek edycji', descriptionBeforeUse: 'Nie możesz tego edytować'
    },
    {
        id: 'secret', name: 'Poufny', color: '#1f94ab', description: 'Ukrywa dokument przed innymi użytkownikami',
        descriptionBeforeUse: ''
    },
];


export const autocompleteConfig = [
    {
        'property-name': 'Kontrahent',
        'property-value': '',
        'default-value': 'Imię Nazwisko',
        'dataApiRoute': 'contractors'
    },
    {
        'property-name': 'Inwestycja',
        'property-value': '',
        'default-value': 'Nazwa Inwestycji',
        'dataApiRoute': 'investments'
    }
];
