
/**
 *  Dependencies list:
 *  - shared
 *  - module
 *  - icons
 */
import { Component } from 'vue-property-decorator';
import ItemsTable from '@/shared/components/layout/list.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import { ModuleProps } from '@/shared/state/template/module-props';

import DocumentsDetails from '../details/details.vue';
import DocumentsForm from '../form/form.vue';
import { documentsModule, documentsListHeaders, Document, DocumentDataRequest } from '../../shared';

import ListView from './list-view.vue';
import TileView from './tile-view.vue';
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';
import { mdiFormatListBulleted, mdiViewGrid } from '@mdi/js';
import FilterBar from '@/shared/components/layout/list/filter-bar.vue';
import { DocumentTemplateDataRequest } from '../../shared/requests/document-template-data-request';
import { documentCategoriesModule, documentTagsModule, documentTemplatesModule } from '../../shared/state/submodules';
import CardPicker from '@/shared/components/layout/form/card-picker.vue';
import Tile from './tile.vue';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import { documentStatuses } from '@/shared/config/documents';
import { DocumentTemplate } from '../../shared/models/document-template';
import { documentsListFilter } from '@/modules/documents/shared/config/filters';
import { tagsModule } from '@/modules/tickets/shared';
import { logger } from '@/shared/services';
import { actionsTypes as adminActionTypes } from '@/modules/admin/shared/state';
import MetaDataForm from '@/modules/documents/components/form/form-meta-data.vue';
import FullEditAskDialog from '@/modules/documents/components/dialogs/full-edit-mode-dialog.vue';
import TemplateItem from './template-item.vue';

@Component({
    components: {
        ItemsTable,
        DocumentsDetails,
        DocumentsForm,
        ListView,
        TileView,
        ListToolbar,
        FilterBar,
        CardPicker,
        Tile,
        ActionConfirmDialog,
        MetaDataForm,
        FullEditAskDialog,
        TemplateItem,
    },
})
export default class DocumentsList extends ListComponent<Document, DocumentDataRequest> {
    public headers: object[] = documentsListHeaders;
    public actionsTypes = documentsModule.actionsTypes;
    public mutationTypes = documentsModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.documentsState;
    public props: ModuleProps = documentsModule.moduleProps;
    public tileView = false;
    public icons = { mdiFormatListBulleted, mdiViewGrid };
    public filters = documentsListFilter;

    public templatesState = this.store.templatesState;
    public templatesModule = documentTemplatesModule;
    public templatesFetchAction = this.templatesModule.actionsTypes.FETCH_DATA;
    public templatesShowAction = this.templatesModule.actionsTypes.SHOW_ITEM;
    public showCardPicker = false;
    public isEditorRestricted = true;

    public editModeDialog = false;
    public metaDataDialog = false;
    public selectedTemplate = {} as any;

    public fullEditStatus = documentStatuses.find((status) => status.id === 'fully-editable');
    public restrictedEditStatus = documentStatuses.find((status) => status.id === 'restricted');
    public filtersCopy: any;
    // @Socket('document-start-edit')
    // dummy(val){
    //     console.log(val)
    // }
    public currentlyEditedDoc: any = null;

    public checkIfUserIsPrivileged(item: any) {
        const user = this.$store.state.authState.user;
        if (user.is_admin) {
            return true;
        } else if (item.creator_id === user.id) {
            return true;
        } else {
            return false;
        }
    }

    public createEmptyDocumentHandler() {
        this.isEditorRestricted = false;
        this.metaDataDialog = true;
    }

    public createItem(item?: DocumentTemplateDataRequest) {
        if (item?.name === 'Pusty Dokument') {
            this.setDefaultData({} as DocumentTemplateDataRequest);
            this.createEmptyDocumentHandler();
            // this.formDialog = true;
            return;
        } else if (this.checkIfUserIsPrivileged(item)) {
            this.editModeDialog = true;
            return;
        }

        if (item) {
            this.transformTemplate(item as any);
            // delete (item as any).name;
            // delete (item as any).id;
        }
        this.setDefaultData(item || ({} as DocumentTemplateDataRequest));
        this.formDialog = true;
    }

    public mounted() {
        this.fetchFiltersData(2, documentCategoriesModule.actionsTypes);
        this.fetchTagsFilters();
        this.setUsersFilters();
        this.fetchData();

        // socket.on('document-start-edit', (val) => console.log(val))
    }

    public openFormWithFullEditMode(fullEditMode: boolean) {
        if (fullEditMode) {
            this.isEditorRestricted = false;
        } else {
            this.isEditorRestricted = true;
        }
        if (this.selectedTemplate) {
            this.transformTemplate(this.selectedTemplate);
            // delete (this.selectedTemplate as any).name;
            // delete (this.selectedTemplate as any).id;
        }
        this.setDefaultData(this.selectedTemplate || ({} as DocumentTemplateDataRequest));
        this.editModeDialog = false;
        this.formDialog = true;
    }

    public onFiltersChange(filters: any) {
        this.filtersCopy = filters;
        this.assignFilters(filters);
    }

    public fetchTagsFilters() {
        const index = 3;
        const tagsActionsTypes = documentTagsModule.actionsTypes;
        this.$store
            .dispatch(tagsActionsTypes.FETCH_DATA, { simple: true })
            .then((data) => {
                this.filters[index].data = data.data;
            })
            .catch((error) => {
                logger.error(error);
            });
    }

    public async setUsersFilters() {
        const index = 1;
        let allUsers = await this.$store.dispatch(adminActionTypes.FETCH_USER_DATA, {
            simple: true,
            filters: { search: '' },
            page: 1,
        });
        allUsers = allUsers.data;
        allUsers = allUsers.map((user: any) => {
            if (user.id === this.$store.state.authState.user.id) {
                user.name += ' (Ja)';
            }
            return user;
        });

        this.filters[index].data = allUsers;
    }

    public fetchFiltersData(index: number, actionsTypes: { [k: string]: string }) {
        this.$store
            .dispatch(actionsTypes.FETCH_DATA, { simple: false })
            .then((res) => {
                this.filters[index].data = res.data.map((el: any) => {
                    return { name: el.id, label: el.name, value: false };
                });
            })
            .catch((error) => {
                logger.error(error);
            });
    }

    private transformTemplate(template: DocumentTemplate) {
        delete (template as any).name;
        delete (template as any).id;
        // (template as any).tags = template.tags.map((tag) => tag.id);
    }
}
