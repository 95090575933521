
import { Component } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

import {documentTagsModule} from '@/modules/documents/shared/state/submodules';
import { DocumentTagDataRequest } from '@/modules/documents/shared/requests/document-tag-data-request';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        FormActions,
    },
})
export default class DocumentTagForm extends FormComponent<DocumentTagDataRequest> {
    public actionsTypes = documentTagsModule.actionsTypes;
    public store: any = this.$store.state.documentsState.tagsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new DocumentTagDataRequest();

    get formTitle(): string {
        return this.edit ? 'Edytuj tag' : 'Dodaj tag';
    }

    public created() {
        //
    }

    public initForm() {
        this.form = this.$refs.createDocumentTagForm;
         }
}
