
import { getUser } from '@/modules/admin/shared/services';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import ItemsTable from '@/shared/components/layout/list.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import { documentStatuses } from '@/shared/config/documents';
import { ModuleProps } from '@/shared/state/template/module-props';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Document, DocumentDataRequest, documentsListHeaders, documentsModule } from '../../shared';
import DocumentsDetails from '../details/details.vue';
import DocumentsForm from '../form/form.vue';

@Component({
    components: {
        ItemsTable,
        DocumentsDetails,
        DocumentsForm,
        TextContrast,
    },
})
export default class ListView extends ListComponent<Document, DocumentDataRequest> {
    @Prop({ type: Boolean, default: true }) public isEditingRestricted!: boolean;
    public headers: object[] = documentsListHeaders;
    public actionsTypes = documentsModule.actionsTypes;
    public mutationTypes = documentsModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.documentsState;
    public props: ModuleProps = documentsModule.moduleProps;
    public isUserAdmin = false;
    public parsedEditedDocuments: any[] = [];

    get editedDocuments(): any[] {
        return this.store.editedDocuments;
    }

    public getStatus(id: string) {
        return documentStatuses.find((status) => status.id === id);
    }

    public getActiveStatus(item: any) {
        if (
            Array.isArray(this.parsedEditedDocuments) &&
            this.parsedEditedDocuments.length > 0 &&
            this.editedDocuments.map((el: any) => Number(el.id)).includes(Number(item.id))
        ) {
            return {
                id: 'edited',
                name: 'Obecnie edytowany',
                color: 'warning',
                description: '',
                descriptionBeforeUse: '',
                user: this.parsedEditedDocuments.find((el: any) => Number(el.id) === Number(item.id)).user,
            };
        }
        if (this.isUserPrivileged(item)) {
            return this.getStatus(item.privileged_status);
        } else {
            return this.getStatus(item.public_status);
        }
    }

    public isUserPrivileged(item: any) {
        return this.isUserAdmin || this.$store.state.authState.user.id === item.creator_id;
    }

    public mounted() {
        this.isUserAdmin = this.$store.state.authState.user?.is_admin || false;
        this.$watch(
            'editedDocuments',
            async (val: any) => {
                if (!val) {
                    return;
                }

                this.parsedEditedDocuments = await Promise.all(
                    val.map(async (el: any) => {
                        const user = await getUser(el.userId);
                        return {
                            ...el,
                            user,
                        };
                    }),
                );
            },
            { immediate: true },
        );

        // this.fetchData();
    }
}
