
import { documentStatuses } from '@/shared/config/documents';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DocumentTemplate } from '../../shared/models/document-template';
// import { component } from 'vue/types/umd';

@Component({})
export default class TemplateItem extends Vue {
    @Prop(Object) public item!: DocumentTemplate;

    public publicStatus() {
        if (!this.item) {
            return null;
        }
        return documentStatuses.find((status: any) => status.id === this.item.public_status);
    }
    public privilegedStatus() {
        if (!this.item) {
            return null;
        }
        return documentStatuses.find((status: any) => status.id === this.item.privileged_status);
    }
}
