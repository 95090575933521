
import { documentStatuses } from '@/shared/config/documents';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component({})
export default class FullEditDialog extends Vue {
    @Prop({type: Boolean, default: false}) public isDocument !: boolean;
    public fullEditStatus = documentStatuses.find((status) => status.id === 'fully-editable');
    public restrictedEditStatus = documentStatuses.find((status) => status.id === 'restricted');
}
