import { documentStatuses } from '@/shared/config/documents';
import { ListFilter, ListFilterDataItem } from '@/shared/types';

export const documentsListFilter: ListFilter[] = [

    {
        name: 'created_at', type: 'date', label: 'Data Utworzenia',
        description: 'Wybierz zakres dat (od - do)'
    },
    // {name: 'paid_at', type: 'date', label: 'Data Płatności', description: 'Wybierz zakres dat (od - do)'},
    {
        name: 'creator_id',
        type: 'search',
        label: 'Twórca',
        description: 'Wybierz twórce dokumentu',
    },
    {
        name: 'category_id',
        type: 'select',
        label: 'Kategoria',
        description: 'Wybierz kategorię',
        data: [],
    },
    { name: 'document_tag_id', type: 'search', label: 'Tag', description: 'Wybierz tagi', chip: true },
    {
        name: 'public_status',
        type: 'select',
        label: 'Status Publiczny',
        description: 'Wybierz status',
        data: documentStatuses.map((status) =>
            ({ label: status.name, value: false, name: status.id } as ListFilterDataItem)),
    },
    {
        name: 'privileged_status',
        type: 'select',
        label: 'Status Uprzywilejowany',
        description: 'Wybierz status',
        data: documentStatuses.map((status) =>
            ({ label: status.name, value: false, name: status.id } as ListFilterDataItem)),

    },

];
export const documentTemplatesListFilter: ListFilter[] = [

    {
        name: 'created_at', type: 'date', label: 'Data Utworzenia',
        description: 'Wybierz zakres dat (od - do)'
    },
    // {name: 'paid_at', type: 'date', label: 'Data Płatności', description: 'Wybierz zakres dat (od - do)'},
    {
        name: 'creator_id',
        type: 'search',
        label: 'Twórca',
        description: 'Wybierz twórce dokumentu',
    },
    {
        name: 'category_id',
        type: 'select',
        label: 'Kategoria',
        description: 'Wybierz kategorię',
        data: [],
    },
    { name: 'tag_id', type: 'search', label: 'Tag', description: 'Wybierz tagi', chip: true },
    {
        name: 'public_status',
        type: 'select',
        label: 'Status Publiczny',
        description: 'Wybierz status',
        data: documentStatuses.map((status) =>
            ({ label: status.name, value: false, name: status.id } as ListFilterDataItem)),
    },
    {
        name: 'privileged_status',
        type: 'select',
        label: 'Status Uprzywilejowany',
        description: 'Wybierz status',
        data: documentStatuses.map((status) =>
            ({ label: status.name, value: false, name: status.id } as ListFilterDataItem)),

    },

];
