
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import { documentStatuses } from '@/shared/config/documents';
import { parseDateTime } from '@/shared/helpers';
import Vue from 'vue';
import { Document } from '@/modules/documents/shared/models/document';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    components: {
        TextContrast,
        NameReduce,
    },
})
export default class DocumentTile extends Vue {
    @Prop(Object) public item!: Document;
    @Prop({ type: Boolean, default: false }) public forTemplate!: boolean;
    public parseDateTime = parseDateTime;

    public activeStatus: any = null;
    public isUserPrivileged: any = false;
    public hover = false;

    public setEditMode() {
        this.isUserPrivileged = this.$store.state.authState.user?.is_admin || false;
        const activeStatusId = this.isUserPrivileged ? this.item.privileged_status : this.item.public_status;
        this.activeStatus = documentStatuses.find((status) => status.id === activeStatusId);
    }

    get thumbnailUrl() {
        if (this.item.hasOwnProperty('blank') && (this.item as any).blank) {
        return `${window.location.origin}/documents/blank/thumbnail`;
        }
        return `${window.location.origin}/documents/${this.forTemplate ? 'templates/' : ''}${this.item.id}/thumbnail`;
    }

    public publicStatus() {
        if (!this.item) {
            return null;
        }
        return documentStatuses.find((status: any) => status.id === this.item.public_status);
    }
    public privilegedStatus() {
        if (!this.item) {
            return null;
        }
        return documentStatuses.find((status: any) => status.id === this.item.privileged_status);
    }

    get getColDivider() {
        switch (this.$vuetify.breakpoint.name) {
            case 'xs':
                return 1;
            case 'sm':
                return 2;
            case 'md':
                return 3;
            case 'lg':
                return 4;
            case 'xl':
                return 6;
        }
    }

    public mounted() {
        this.setEditMode();
    }
}
