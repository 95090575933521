
/**
 *  Dependencies list:
 *  - shared
 *  - module
 */
import {Component, Watch} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import DocumentEditor from '@/modules/documents/components/editors/document-editor.vue';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import {documentsModule, DocumentDataRequest} from '../../shared';
import PreventUnload from '@/shared/PreventUnload.vue';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';
import FormToolbar from './form-toolbar.vue';
import {logger} from '@/shared/services';
import {DocumentTag} from '@/modules/documents/shared/models/document-tag';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        FormActions,
        ActionConfirmDialog,
        DocumentEditor,
        TopToolbar,
        PreventUnload,
        AutoCompleteWithAddNew,
        InvestmentTagChip,
        FormToolbar,
    },
    props: {
        restricted: {
            type: Boolean,
            default: false,
        },
    },
    // TODO: Przerobić na presence channel w Pusher
    // beforeRouteLeave(to, from, next) {
    //     next(() => {
    //         this.$socket.client.emit('document-end-edit', {id: this.$route.params.id});
    //     });
    // },
})
export default class DocumentsForm extends FormComponent<DocumentDataRequest> {
    public actionsTypes = documentsModule.actionsTypes;
    public store: any = this.$store.state.documentsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new DocumentDataRequest();

    get formDialog() {
        return this.store.formDialog;
    }

    set formDialog(val: boolean) {
        this.store.formDialog = val;
    }

    public created() {
        this.form = this.$refs.createDocumentForm;
    }

    public initForm() {
        this.form = this.$refs.createDocumentForm;
         }

    get formTitle() {
        return this.edit ? 'Edytuj Dokument' : 'Dodaj Dokument';
    }

    public async submitForm(e: any, next?: boolean) {
        if (!this.form || this.busy) {
            return;
        }

        await this.form.validate();

        if (this.valid) {
            const action = this.edit ? this.updateAction : this.storeAction;
            this.busy = true;

            const data = this.requestParams;
            if (data.tags && data.tags.every((tag: DocumentTag) => typeof tag !== 'number')) {
                data.tags = data.tags.map((tag: DocumentTag) => tag.id);
            }

            this.$store
                .dispatch(action, data)
                .then((response) => {
                    if (
                        (response && response.data && response.data.id) ||
                        (response && response.hasOwnProperty('status') && response.status)
                    ) {
                        this.valid = true;
                        this.form.reset();
                        this.$emit('saved', {data: response.data, next, edit: action === this.updateAction});
                        if (this.$route.name !== 'document-details') {
                            this.$router.push({name: 'document-details', params: {id: response.data.id}});
                        }
                        // TODO: Przerobić na presence channel w Pusher
                        // this.$nextTick(() => {
                        //     this.$socket.client.emit('document-end-edit', {id: response.data.id});
                        // });

                        if (next) {
                            this.$emit('next', response.data);
                            this.initFocus();
                            this.formNextCallback(response.data);
                        } else {
                            this.formCallback(response.data);
                        }
                    }
                })
                .catch((err) => logger.error(err))
                .then(() => {
                    this.busy = false;
                });
        }
    }
    // TODO: Przerobić na presence channel w Pusher
    // @Watch('formDialog')
    // public onFormDialogChange(val: any) {
    //     if (!val) {
    //         this.$socket.client.emit('document-end-edit', {id: this.itemData.id});
    //     }
    // }
}
