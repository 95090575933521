
import { Component, Watch } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { DocumentCategoryDataRequest } from '@/modules/documents/shared/requests/document-category-data-request';
import { documentCategoriesModule } from '@/modules/documents/shared/state/submodules';


@Component({
    components: {
        FormActions,
    },
})
export default class DocumentsCategoryForm extends FormComponent<DocumentCategoryDataRequest> {
    public actionsTypes = documentCategoriesModule.actionsTypes;
    public store: any = this.$store.state.documentsState.categoriesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new DocumentCategoryDataRequest();


    public swatches: Array<[string, string]> = [
        ['#753BBD', '#147BD1'],
        ['#2DC84D', '#FF7F41'],
        ['#F7EA48', '#ea97ad'],
        ['#4A6695', '#E03C31'],
        ['#B7CC33', '#FF00A5'],
    ];


    public created() {
        /**/
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj kategorię' : 'Dodaj kategorię';
    }

    public initForm() {
        this.form = this.$refs.createDocumentCategoryForm;
    }

    @Watch('itemData.color')
    public ifColorObject() {
        if (!(typeof this.itemData.color === 'string')) { this.itemData.color = '#147BD1'; }
    }
}
