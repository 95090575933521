var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-card',[_c('list-toolbar',{attrs:{"key-shortcut":['ctrl', 'alt', 'n'],"add-button":!_vm.props.readOnly,"add-button-title":"Dodaj","disabled":!_vm.isPermitted('create'),"add-button-tooltip":_vm.isPermitted('create')? '' : 'Brak uprawnień'},on:{"create-item":function($event){_vm.showCardPicker = true}},scopedSlots:_vm._u([{key:"search",fn:function(){return [_c('v-text-field',{staticClass:"pt-sm-0 mb-5 mb-sm-0",class:!_vm.props.readOnly ? 'pr-sm-8' : '',attrs:{"append-icon":"mdi-magnify","label":"Szukaj","single-line":"","hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticStyle:{"cursor":"default"}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('v-card-text',[_vm._v(" Możesz szukać po kolumnie:"),_c('br'),_c('strong',[_vm._v("Tytuł")]),_vm._v(" "),_c('br')])],1)]},proxy:true}])})]},proxy:true}])},[_vm._v(" Dokumenty ")]),_c('filter-bar',{attrs:{"options":_vm.filters},on:{"filtersChanged":function($event){return _vm.onFiltersChange($event)}}}),_c('list-view',{attrs:{"isEditingRestricted":_vm.isEditorRestricted}}),(_vm.isPermitted('create'))?_c('card-picker',{staticClass:"d-inline",attrs:{"store":_vm.templatesState,"fetchAction":_vm.templatesFetchAction,"showAction":_vm.templatesShowAction,"onlyPicker":"","showPicker":_vm.showCardPicker,"itemsToAppend":[
                {
                    name: 'Pusty Dokument',
                    blank: true,
                },
            ]},on:{"update:showPicker":function($event){_vm.showCardPicker=$event},"update:show-picker":function($event){_vm.showCardPicker=$event},"input":function($event){return _vm.createItem($event)}},scopedSlots:_vm._u([{key:"card",fn:function({ item, select }){return [_c('template-item',{attrs:{"item":item},nativeOn:{"click":function($event){return select.apply(null, arguments)}}})]}}],null,false,20890500),model:{value:(_vm.selectedTemplate),callback:function ($$v) {_vm.selectedTemplate=$$v},expression:"selectedTemplate"}}):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","width":"400"},model:{value:(_vm.editModeDialog),callback:function ($$v) {_vm.editModeDialog=$$v},expression:"editModeDialog"}},[_c('full-edit-ask-dialog',{on:{"choice":_vm.openFormWithFullEditMode}})],1),_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.metaDataDialog),callback:function ($$v) {_vm.metaDataDialog=$$v},expression:"metaDataDialog"}},[(_vm.store)?_c('meta-data-form',{key:_vm.metaDataDialog,attrs:{"itemData":_vm.editedItem,"edit":false,"restricted":false,"before-editor":""},on:{"close":($event) => (_vm.metaDataDialog = false),"continue":($event) => {
                        if ($event) {
                            _vm.metaDataDialog = false;
                            _vm.formDialog = true;
                        }
                    }}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }