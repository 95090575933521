
import ItemsTable from '@/shared/components/layout/list.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import { ModuleProps } from '@/shared/state/template/module-props';
import Component from 'vue-class-component';
import { Document, DocumentDataRequest, documentsListHeaders, documentsModule } from '../../shared';
import DocumentsDetails from '../details/details.vue';
import DocumentsForm from '../form/form.vue';
import DocumentTile from './tile.vue';

@Component({
    components: {
        ItemsTable,
        DocumentsDetails,
        DocumentsForm,
        DocumentTile,
    },
})
export default class TileView extends ListComponent<Document, DocumentDataRequest> {
    public headers: object[] = documentsListHeaders;
    public actionsTypes = documentsModule.actionsTypes;
    public mutationTypes = documentsModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.documentsState;
    public props: ModuleProps = documentsModule.moduleProps;

    public mounted() {
        this.fetchData();
    }
}
