import { render, staticRenderFns } from "./details.vue?vue&type=template&id=422d20a7&scoped=true&"
import script from "./details.vue?vue&type=script&lang=ts&"
export * from "./details.vue?vue&type=script&lang=ts&"
import style0 from "./details.vue?vue&type=style&index=0&id=422d20a7&prod&lang=scss&scoped=true&"
import style1 from "./details.vue?vue&type=style&index=1&id=422d20a7&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "422d20a7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VScrollXReverseTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VAlert,VAvatar,VBtn,VCard,VDialog,VExpandTransition,VFadeTransition,VIcon,VImg,VProgressCircular,VProgressLinear,VScrollXReverseTransition})
